@import "../template/mixin_templates";
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700);

/* Variables */

$xlarge-screen-reduit: $xlarge-screen - 80;

/*$small-screen: 640px;
$medium-screen: 1024px;
$large-screen: 1200px;
$xlarge-screen: 1440px;*/

/*****************************************************/
/********************* keyframes *********************/
/*****************************************************/

/***************************************************/
/********************* GENERAL *********************/
/***************************************************/

body, html {
    font-size: 18px;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
}

*, *:before, *:after {
    box-sizing: inherit;
}

@include titreBaseSize();

h2, h3, h4,
.h2, .h3, .h4 {
    font-weight: 300;
}

h1, .h1 {
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
}

h2, .h2 {
    padding: 15px;
    font-size: 1.5rem;
    display: inline-block;
    min-width: 50%;
}

h3, .h3 {
    font-size: 1.3rem;
}

#main-conteneur h4, #main-conteneur .h4 {
    text-decoration: underline;
}

a {
    text-decoration: none;
    img {
        border: none;
    }
}

a {
    transition: all .2s linear;
}

#logo {
    transition: none;
}

img:not(.img-logo) {
    max-width: 100%;
    height: auto !important;
}

ul {
    padding: 0;
    list-style: none;
}

iframe {
    max-width: 100%;
}

table {
    position: relative;
    table-layout: fixed;
    max-width: 100% !important;
    a {
        max-width: 100%;
    }
}

header{
    position:relative;
    z-index: 1050;
    &.header_p{
        background-position: top center !important;
    }
}

/* Conteneur principal */
.wrapper, #headercontent, #pub {
    width: $xlarge-screen-reduit;
    margin: 0 auto;
    position: relative;
    padding: 20px;
    //z-index: 1;
}

#headercontent {
    padding: 0;
    z-index: 3;
}

.wrapper-content {
    overflow: hidden;
}

#wrapper::after {
    content: '';
    display: block;
    clear: both;
}

.content_p {
    position: relative;
    > section ul {
        margin-left: 20px;
        list-style-type: disc;
    }
}

#tinymce ul {
    margin-left: 20px;
    list-style-type: disc;
}

/* site en deux colonnes */
.sidebar, section.col-md-10 {
    float: left;
    padding: 20px;
}

.sidebar {
    li a::before {
        content: '- ';
        margin: 0 5px;
        display: inline-block;
    }
}

section.col-md-10 {
    width: 100%-(300px/$xlarge-screen-reduit * 100%);
}

/* fil d'Arianne */
.nav-static-breadcrumb {
    font-size: 0;
    li {
        display: inline-block;
        font-size: 1rem;
        &:not(:first-child)::before {
            content: '> ';
            display: inline-block;
            margin: 0 10px;
            font-weight: 300;
        }
    }
    a {
        font-weight: 300;
        font-size: .8em;
    }
}

/**************************************************/
/********************* HEADER *********************/
/**************************************************/

#headercontent {
    text-align: center;
    min-height: 180px;
    box-sizing: border-box;
}

.logo {
    display: inline-block;
}

.logo img {
    height: 100px;
    max-width: 100%;
}

.slogan {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

/************************************************/
/********************* MENU *********************/
/************************************************/

body.cmonsite-panier-2 nav#nav-principal {
    > ul {
        max-width: calc(100% - 149px);
    }
}

nav#nav-principal {
    position: relative;
    z-index: 2;
    padding: 0 20px;
    width: $xlarge-screen-reduit;
    margin: auto;
    > ul {
        float: left;
        font-size: 0;
        width: auto;
        max-width: calc(100% - 100px);
        &.navIcons {
            max-width: 100px;
            padding-right: 0 !important;
        }
        > li > ul {
            padding-top: 1px;
        }
        ul {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            min-width: 200px;
            li {
                display: block;
            }
            ul {
                left: 100%;
                top: 0;
            }
        }
    }
    li {
        display: inline-block;
        font-size: 1rem;
        position: relative;
        transition: all .2s linear;
        &:hover {
            > ul {
                display: block;
            }
        }
    }
    a:not(.button) {
        width: 100%;
        padding: 21px 25px;
        display: block;
        font-size: 14px;
        line-height: 21px;
        //text-transform:uppercase;
        min-height: 55px;
        box-sizing: border-box;
        font-weight: 300;
    }
    &::after {
        content: '';
        clear: both;
        display: block !important;
    }
    .p-contact{
        display: none;
    }
    .navIcons {
        float: right;
        li {
            float: right;
            width: 50px;
            position: relative;
            margin-right: -1px;
            &:first-child {
                margin-right: 0;
            }
            a {
                padding: 0;
                text-align: center;
                line-height: 63px;
                &::before {
                    width: 100%;
                    text-align: center;
                    display: block;
                    line-height: 61px;
                    font-family: Fontawesome, sans-serif;
                }
            }
            span.navigation_legend_top {
                display: none;
                position: absolute;
                bottom: 110%;
                border: 1px solid #e4e4e4;
                text-align: center;
                font-size: .9rem;
                white-space: nowrap;
                padding: 2px 5px;
                line-height: normal;
                transform: translateX(-50%);
                left: 50%;
            }
            &:hover, &.actif {
                span.navigation_legend_top {
                    display: block;
                }
            }
            &.p-contact{
                display: block;
            }
            span button.button{
                background: transparent !important;
                color: inherit !important;
                border:none !important;
                width: 100%;
                line-height: 63px;
                height: 63px;
                padding: 0;
                position: relative;
                cursor: pointer;
            }
            .input-group-btn, .input-group, input.form-control{
                display: block;
                width: 100%;
                height: 100%;
                margin-left: 0;
            }
            input.form-control{
                position: absolute;
                right: 100%;
                left: auto;
                padding:0;
                width: 0;
                visibility: hidden;
                transition:width .2s linear, padding .1s linear, visibility 0s linear .2s;
            }
            &.actif{
                input.form-control{
                    transition-delay: 0s;
                    width: 250px;
                    padding:0 15px;
                    visibility: visible;
                }
            }
        }
    }
}

aside.sidebar nav {
    a {
        display: block;
        text-align: left;
        padding: 10px 0 10px 20px;
    }
    > ul > li > a {
        margin: 0 0 10px;
        text-align: center;
        padding: 10px 0;
        text-transform: uppercase;
    }
    ul {
        margin-top: 20px;
    }
    > ul {
        margin-top: 0;
    }
    > ul > li > a::before {
        display: none;
    }
    li {
        display: block;
        list-style: none;
        position: relative;
        a::before {
            content: '';
            position: absolute;
            left: 0;
            opacity: 0;
            transition: opacity .2s linear, left .2s linear;
        }
        ul {
            margin: 0;
        }
        &:hover > a::before {
            content: "\f1db";
            font-family: Fontawesome, sans-serif;
            font-size: 10px;
            line-height: 3em;
            opacity: 1;
        }
        li {
            border-width: 0;
            a {
                display: inline-block;
                transition: all .2s linear;
                width: 100%;
                box-sizing: border-box;
                padding-left: 20px;
                &::before {
                    left: 0;
                }
            }
            &:hover > a {
                padding-left: 30px;
                &::before {
                    left: 10px;
                }
            }
            li {
                a {
                    padding-left: 40px;
                    &::before {
                        left: 20px;
                    }
                }
                &:hover > a {
                    padding-left: 50px;
                    &::before {
                        left: 30px;
                    }
                }
            }
        }
    }
}

/****************************************************/
/********************* Produits *********************/
/****************************************************/

.container-etiquette {
    width: 100%;
    position: absolute;
    top: 0
}

.accueil_boutique_template_1 .container-etiquette {
    position: absolute;
}


/*Général*/
.produits {
    float: left;
    width: 24%;
    margin: .5%;
    position: relative;
    z-index: 3;
    padding: 0 0 15px;
    .remise {
        position: absolute;
        z-index: 100;
        left: auto;
        right: 0;
        margin-top: 0;
        padding: 10px;
        font-size: 15px;
        span {
            font-size: 15px;
        }
    }
    .productImageWrap {
        overflow: hidden;
        top: 0;
        left: 0;
        img {
            width: 100%;
        }
    }
    .nomprod {
        text-transform: uppercase;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
        font-weight: 300;
        a {
            line-height: normal;
        }
    }
    .mask {
        position: absolute;
        width: 100%;
        a {
            opacity: 0;
        }
    }
    a.zoom {
        opacity: 0;
        &::before {
            text-align: center;
        }
    }
    .prix {
        text-align: center;
    }
    .oldprix, .prixprod {
        display: inline;
        float: none;
    }
    .oldprix {
        text-decoration: line-through;
        font-weight: 300;
        font-size: .8rem;
        margin: 0 4px 10px;
    }
    .prixprod {
        font-weight: 300;
    }
    .addbasket {
        padding: 10px;
        transition: all .2s linear;
    }
    .attr {
        margin: 5px;
    }
    &.is_etiquette {
        .remise {
            top: 39px;
            margin-top: 0;
        }
    }
    &:hover {
        .mask a {
            opacity: .5;
        }
        a.zoom {
            opacity: .5;
        }
    }
}

.addbasket, .bouton, .button {
    padding: 10px;
    transition: all .2s linear;
    text-align: center;
}

.bouton, .button {
    margin: 10px 0;
    clear: both;
    display: inline-block;
}

.template_boutique_all{
    .produits{
        .produit_etiquette{
            position: absolute;
        }
        .remise{
            top: 0;
            height: auto;
            padding: 10px;
            span{
                font-size: 20px;
            }
        }
        &.is_etiquette .remise{
            top: 40px;
        }
    }
}

.template_boutique_accueil.produits-accueil {
    padding:40px 0;
    position:relative;
    /*&:before{
        content:'';
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width: 100%;
        padding: 0 9999px;
        margin-left: -9999px;
        margin-right: -9999px;
        box-sizing: content-box;
        margin-top: 0;
    }*/
}

.cmonsite-index #wrapper {
    padding-top: 0;
    //overflow: inherit;
}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
    width: 100%;
    max-width: 100%;
    h3, .h3 {
        line-height: 20px;
        padding: 8px 0;
    }
}

.template_boutique_3 .produits .remise {
    top: 10px;
    right:10px;
}

/*********************************************************/
/********************* FICHE PRODUIT *********************/
/*********************************************************/

/* miniatures photos */
#fiche-produit .previews .previous, .previews .next {
    width: auto;
}

#fiche-produit .wrap-images .previews .list-previews {
    width: 71%;
}

/* Permet de bien afficher le hover des images fiches produits pour le zoom */
.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
    max-width: none;
}

.triangle-ficheprod-second,
.triangle-ficheprod {
    position: absolute;
    right: 0;
    top: 0;
}

.template_fiche_produit{
    #fiche-produit {
        .top_fiche {
            margin-top: 25px;
            .button {
                display: inline-block;
            }
        }
        .wrap-description {
            h3, .h3 {

            }
            .h1 {
                max-width: -webkit-calc(100% - 75px);
                max-width: calc(100% - 75px);
                margin-top: 0;
                text-transform: uppercase;
                padding: 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                display: inline-block;
                font-size: 24px;
                text-align: left;
            }
        }
        .declinaison{
            .declinaison-attribute-group,.product_options-group{
                label, select, input[type="text"]{
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 100%;
                }
                label:first-child{
                    margin:5px 5px 5px 0;
                }
                select{
                    margin:0;
                    padding: 8px;
                }
                input[type="text"]{
                    padding: 8px;
                    margin:0;
                }
                .radio_unique, .radio_color{
                    margin:5px;
                }
            }
        }

        h2[itemprop="brand"]{
            margin-top: 0;
        }
        //h1, .h1 {
        //    padding: 20px;
        //    text-align: center;
        //    margin-top:0;
        //}
        .top_fiche {
            font-size: 1rem;
        }
        .triangle-ficheprod-second .remise-produit,
        .triangle-ficheprod .remise-produit {
            border-radius: 0;
            padding: 15px;
            width: auto;
            height: auto;
            margin-right:1rem;
        }
        .remise-produit {
            height: auto;
            width: auto;
            span{
                font-size: 20px;
            }
        }
        .oldprix {
            font-weight: 300;
        }
        label, .prixprod {
            font-weight: 300;
        }
        .prix {
            border: none;
            margin: 10px 0;
        }
        .sociaux {
            clear: both;
            float: none;
            border: none;
            text-align: center;
            width: 100%;
            iframe {
                display: inline-block;
            }
        }
        .remise-produit {
            font-weight: 300;
        }
        .wrap-images{
            .remise-produit {
                //display: none;
            }
        }
        .addbasket span, .addbasket i {
            font-size: 1.3rem;
            margin-right: 10px;
            margin-right: 0.3em;
        }
        .previews {
            position: relative;
            height: 75px;
            clear: both;
            .list-previews {
                width: 100%;
                height: 100%;
                position: absolute;
            }
            > span {
                bottom: 0;
                width: 30px;
                height: 75px;
                text-align: center;
                transition: all .2s linear;
                &::before {
                    content: '>';
                    line-height: 75px;
                    font-size: 20px;
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-indent: initial;
                    transition: all .2s linear;
                }
                &.previous::before {
                    content: '<';
                }
                i {
                    display: none;
                }
            }
        }
        .zoom-img-position {
            margin: 0;
        }
    }
    &.template_fiche_produit_1 #fiche-produit {
        .wrap-images{
            .container-etiquette ~ .triangle-ficheprod .remise-produit{
                top: 40px;
            }
            .remise-produit {
                margin-right: 0;
                //display: none;
            }
        }
        .wrap-description {
            h3, .h3 {
                max-width: calc(100% - 75px);
                margin-top: 14px;
                text-transform: uppercase;
                padding: 10px 10px 10px 0;
                box-sizing: border-box;
                display: inline-block;
            }
        }
        .avec-declinaison {
            .declinaison {
                float: left;
                width: 52%;
                border: 1px solid #e4e4e4;
                padding: 20px;
                box-sizing: border-box;
                label{
                    margin-bottom:0;
                    &:first-child{
                        margin-right:5px;
                    }
                }
            }
            .ajouter-panier, .details-ficheprod {
               // text-align: center;
            }
            .ajouter-panier, .description, .bloc-quantite, .details-ficheprod, .notification_product {
                float: left;
                width: 51%;
                //text-align: center;
            }
            .notification_product{
                margin-top: 0;
                &.hors-stock{
                    height: auto;
                }
            }
            .triangle-ficheprod{
                position: absolute !important;
            }
            input.quantite {
                margin-bottom: 0;
            }
            .pro-pannier {
                padding: 0 10px;
            }
            .sociaux {
                float: left;
                width: 47%;
            }
            .description + p {
                clear: both;
            }
        }
        .sans-declinaison {
            .pro-pannier {
                float: left;
                width: 50%;
            }
            .description {
                float: right;
                width: 50%;
            }
            .sociaux {
                float: right;
                width: 50%;
                box-sizing: border-box;
            }
            .button.btn-details {
                margin-top: 10px;
            }
        }
    }
    &.template_fiche_produit_2 #fiche-produit {
        .container-etiquette{
            width:calc(100% - 1rem);
        }
        .wrap-images .triangle-ficheprod {
            top: 0;
            right: 0;
        }
        .remise-produit{
            left: auto;
        }
    }
    &.template_fiche_produit_3 #fiche-produit {
        .container-etiquette {
            width: calc(100% - 1rem);
        }
    }
}

.template_fiche_produit_1 #fiche-produit .wrap-description p {
    float: left;
    width: 100%;
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > .button {
        flex: 0 1 auto;
        font-size: 1.5em;
        margin: 15px 0;
    }
    > .button + .button {
        margin-left: 15px;
    }
}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}

/****************************************************/
/********************* Galeries *********************/
/****************************************************/

.addcommentaires {
    line-height: 17px;
}

body .template_album_3 .list-photos .galerie.sans-texte .bloc-cache-overflow {
    display: none;
}

.template_album_2,
.template_album_3{
    .galerie{
        .commentP{
            p{
                text-align: center;
                opacity: 0;
                padding:10px;
            }
        }
    }
}

.template_album{
    .galerie{
        .commentP{
            p{
                margin: 0;
                transition:opacity .2s linear;
            }
        }
        &:hover{
            .commentP{
                p{
                    opacity: 1;
                }
            }
        }
    }
}

/**************************************************/
/********************* Slider *********************/
/**************************************************/

.bx-wrapper .bx-viewport {
    left: 0;
}

.accueil_diaporama_template_2 {
    .bx-pager {
        display: none;
    }
}

.accueil_diaporama_template {
    .bx-wrapper {
        margin-bottom: 0;
    }
    .bx-controls-direction a {
        background: transparent;
        width: 30px;
        height: 150px;
        margin-top: -75px;
        text-align: center;
        transition: all .2s linear;
        overflow: hidden;
        &::before {
            content: '';
            line-height: 32px;
            font-size: 20px;
            display: block;
            width: 100%;
            height: 100%;
            text-indent: initial;
            transition: all .2s linear;
        }
        &.bx-prev::before {
            content: "\f053";
        }
        &.bx-next::before {
            content: "\f054";
        }
    }
    .slide {
        //width:100%;
        //height:100%;
        position: relative;
        > div {
            max-height: 600px;
        }
        li:hover .slide-text,
        div:hover .slide-text {
            opacity: 1;
        }
    }
    .slide .slide-text {
        width: 50%;
        transform: translateY(-50%);
        left: 25%;
        bottom: auto;
        top: 50%;
        right: auto;
        opacity: 0;
        display: block;
        position: absolute;
        transition: all .2s linear;
        text-align: center;
        height: auto;
        padding: 100px 20px;
        &.nocomment {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
            width: 100%;
            left: 0;
            height: 100%;
            transform: translateY(0) translateX(0);
            background: transparent !important;
            > a.slider-see-more {
                bottom: auto;
                transform: translateY(0) translateX(0);
                top: 85px;
                right: 60px;
            }
            .content-slide-text > a.addcommentaires {
                transform: translateX(0);
                width: 75px;
                height: 55px;
                box-sizing: border-box;
                top: 30px;
                right: 60px;
                margin: 0;
                padding: 0;
                line-height: 55px;
                text-align: center;
            }
        }
        .content-slide-text > p {
            text-align: center;
            font-size: 30px;
            box-sizing: border-box;
            position: relative;
            font-weight: 300;
            left: 0;
            bottom: auto;
            top: 0;
            right: auto;

        }
        .content-slide-text > a.addcommentaires {
            position: absolute;
            top: 75px;
            right: 50%;
            transform: translateX(50%);
            line-height: normal;
        }
        .content-slide-text > a.slider-see-more {
            position: absolute;
            top: auto;
            bottom: 20px;
            right: 50%;
            transform: translateX(50%);
            //margin:0;
            line-height: normal;
        }
    }
}

.wrapper.accueil_diaporama_template.accueil_diaporama_template_5 .slide {
    .slide-text {
        width: 100%;
        left: 0;
        height: 100%;
        padding: 0 20px;
        display: block;
        font-size: 0;
        .content-slide-text {
            display: inline-block;
            vertical-align: middle;
            font-size: 1rem;
            position: relative;
            width: 100%;
            p {
                line-height: initial;
                margin: 0 auto;
            }
            > a.slider-see-more {
                top: 100%;
                bottom: auto;
                left: 0;
                right: 0;
                transform: translateX(0);
            }
            > a.addcommentaires {
                top: auto;
                bottom: 100%;
            }
        }
        &.nocomment .content-slide-text > a.addcommentaires {
            transform: translateX(50%);
            right: 50%;
        }
        &.nocomment.nolink .content-slide-text > a.addcommentaires {
            right: 50%;
            bottom: 100%;
            transform: translate(50%, 50%);
        }
        &::before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
}

/******************************************************/
/*********************** CONTACT **********************/
/******************************************************/

form{
    .form-group{
        input[type="text"], input[type="password"], textarea, select{
            padding:8px;
            border:1px solid;
            opacity:.4;
            transition:opacity .1s linear;
        }
        input[type="text"], input[type="password"], textarea{
            margin-top:5px;
            &:hover, &:focus{
                margin-top:5px;
                border-color:currentColor;
                opacity: 1;
            }
        }
        input[type="text"], input[type="password"]{
            border-width:0 0 1px 0;
        }
        > label:first-child{
            font-weight: normal;
            font-variant: small-caps;
        }
        .groupe-radio, .groupe-checkbox{
            position:relative;
            input{
                //display: none;
                position: absolute;
                opacity: 0;
            }
            label{
                margin-left: 0;
                &::before{
                    content:'';
                    display: inline-block;
                    vertical-align: middle;
                    border:1px solid currentColor;
                    border-radius:50%;
                    width:15px;
                    height: 15px;
                    background:transparent;
                    opacity:.4;
                    transition:background .2s linear, opacity .2s linear;
                    margin-right: 10px;
                }
                &:hover::before{
                    opacity: 1;
                }
            }
            input:checked + label::before{
                background:currentColor;
                opacity:1;
            }
        }
        .groupe-checkbox{
            label::before{
                border-radius:0;
            }
        }
    }
}
.form-newsletter {
    div:last-child{
        width: 100%;
    }
    input[type="file"]{
        border:none;
    }
    .qs_form{
        border:none;
    }
}
.formulaires_perso {
    .qs_form {
        border:none;
    }
    input[type="text"]{
        width: 100%;
    }
}
.roundedgraymsg{
    font-weight: normal;
}

.template_contact{
    &.template_contact_3,
    &.template_contact_4{
        .form-group{
            input[type="text"], textarea{
                margin-top: 0;
                &:hover, &:focus{
                    margin-top: 0;
                }
            }
        }
    }
    &.template_contact_5{
        .form-group{
            input[type="text"], textarea, select{
                transition:opacity .1s linear, border .1s linear, padding .1s linear;
            }
        }
    }
}

/******************************************************/
/********************* Livre d'or *********************/
/******************************************************/

.template_livreor_1 .message_lo {
    padding: 20px;
    border: 1px solid;
}

/**************************************************/
/********************* FOOTER *********************/
/**************************************************/

footer {
    padding: 20px;
    text-align: center;
    clear: both;
    width: 100%;
    .mention.links_p a {
        font-weight: 300;
        text-transform: uppercase;
        display: inline-block;
        margin-top: 20px;
    }
    #pub{
        padding: 0 20px;
    }
}

/**************************************************/
/********************* AUTRE **********************/
/**************************************************/

/* Hauteur de la sidebar d'icones */
aside#minisite-sidebar {
    top: 28%;
}

/* Template Panier */
body.cmonsite-panier-2 .template-panier.template-panier-2 {
    height: 63px;
    width: 50px;
    border: none !important;
    #panier #header-panier {
        padding: 10px;
        box-sizing: border-box;
        display: block;
        height: auto;
        text-align: center;
        font-size: 0;
        line-height: 42px;
        .fa {
            font-size: 18px;
            margin: 0;
        }
        .count-live {
            font-size: 10px;
            top: auto;
            margin-top: 9px;
            margin-left: 0;
            left: 26px;
        }
        .paniertoggle .fa {
            display: none;
        }
    }
}

body.cmonsite-panier-3 #blocContent .template-panier.template-panier-3 {
    border: none !important;
    top: 10px;
    right: 10px;
    * {
        font-weight: normal;
    }
}

body .template-panier:not(.template-panier-1) {
    .produit_panier .panier_quantite {
        margin-bottom: 5px;
    }
}


//Suppression des blocs de recherche
nav#nav-principal li.b-search{
    display: none;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .logo-carrier-none {
        width: auto;
    }

    h4 {
        margin: 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

/*********************************************************/
/********************* MEDIA QUERIES *********************/
/*********************************************************/

@media #{$xlarge-and-down} {
    .wrapper, nav#nav-principal, #headercontent, #pub {
        width: $large-screen - 80;
    }
    /* fiche produit */
    #fiche-produit .wrap-images .previews .list-previews {
        width: 69%;
    }
}

@media #{$large-and-down} {
    .wrapper, nav#nav-principal, #headercontent, #pub {
        width: $medium-screen - 80;
    }
    /* Fiche produit */
    #fiche-produit .wrap-images .previews .list-previews {
        width: 64%;
    }
}

@media #{$medium-and-down} {
    /* Template panier */
    body.cmonsite-panier-2 {
        .navIcons {
            margin-right: 50px;
        }
        .template-panier.template-panier-2 {
            top: 0;
            #header-panier {
                height: 61px !important;
                line-height: 44px !important;
                bottom: 0 !important;
                top: auto !important;
                right: 20px !important;
                box-shadow: none !important;
            }
        }
    }

    .wrapper, nav#nav-principal, #headercontent, #pub {
        width: 90%;
    }
    #headercontent {
        width: 100%;
    }

    /* Met une position par défaut du logo et du slogan */
    .slogan {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        margin-top: 35px !important;
        margin-bottom: 15px !important;
        text-align: center !important;
        font-size: 1.2rem !important;
        width: 100% !important;
        display: block;
    }
    #logo {
        position: relative !important;
        width: 100% !important;
        text-align: center !important;
        left: 0 !important;
        top: 20px !important;
        display: block;
        img {
            width: auto !important;
            max-height: 60px;

        }
    }
    #headercontent {
        min-height: 0;
        margin-bottom: 20px;
    }
    /* Passage des deux colonnes en une */
    .sidebar, section.col-md-10 {
        float: none;
        padding: 0;
    }
    .sidebar {
        width: 100%;
        padding: 20px;
        li a::before {
            content: '- ';
            margin: 0 5px;
            display: inline-block;
        }
    }
    section.col-md-10 {
        margin-top: 20px;
        width: 100%;
    }
    /* Fiche produit */
    #fiche-produit .wrap-images .previews .list-previews {
        width: 49%;
    }

    .template_fiche_produit.template_fiche_produit_1 #fiche-produit .avec-declinaison {
        .declinaison, .ajouter-panier, .description, .bloc-quantite, .details-ficheprod, .notification_product {
            float: none;
            width: 100%;
            text-align: left;
        }
        .notification_product{
            margin-top: 10px;
        }
        .sociaux {
            width: 100%;
        }
    }

    aside.sidebar {
        display: none;
    }

    .wrapper.accueil_diaporama_template .slide .slide-text {
        padding: 50px 20px !important;
        width: 75% !important;
        left: 12.5% !important;
        .content-slide-text > a.addcommentaires {
            top: 5px !important;
        }
        .content-slide-text > p {
            margin: 0 !important;
            font-size: 25px;
        }
        .content-slide-text > a.slider-see-more {
            bottom: 5px !important;
        }
    }
}

@media #{$medium-and-up}{
    .menu_burger {
        display: none;
    }
}


@media (max-width: #{$medium-breakpoint-down}) {

    /* Menu responsive */
    nav#nav-principal {
        width: 100%;
        padding: 0;
        > ul {
            position: absolute !important;
            z-index: 1010;
        }
        ul {
            width: 100%;
            max-width: 100% !important;
            ul {
                position: relative;
            }
        }
        li {
            width: 100%;
            display: block;
        }
        a {
            /*Font size + padding : il faut au moins que le bloc fasse 40px de haut, taille minimale pour un bouton au doigt*/
            font-size: 1rem;
            line-height: 35px;
            padding: 12px 0;
            width: 100%;
            text-align: center;
            &.menu_burger{
                height: 63px;
            }
        }
        > ul:not(.navIcons) {
            display: none;
        }
        &.nav_ouverte {
            > ul {
                display: block;
            }
        }

        /* Gestion contact/livre d'or */

        ul.navIcons {
            top: 0 !important;
            display: inline-block !important;
            height: 61px !important;
            right: 0;
            width: auto !important;
            position: absolute;
        }
    }
    .menu_burger {
        text-align: center;
        &::before {
            content: '\f0c9';
            display: inline-block;
            margin-right: 5px;
            font-family: 'FontAwesome', sans-serif;
        }
    }
    .dropDown {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: bold;
        display: block;
        height: 59px;
        width: 60px;
        line-height: 59px;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        &::before {
            font-family: FontAwesome, sans-serif;
            content: "\f0d7";
        }
    }
    nav#nav-principal > ul ul ul {
        left: 0;
    }
}
@media #{$small-and-down} {

    body.cmonsite-panier-2 {
        #nav-principal > .menu_burger {
            padding-right: 150px !important;
        }
        .template-panier.template-panier-2 {
            #header-panier {
                right: 0 !important;
            }
        }
    }
    .template-panier-3 .count-live{
        margin-left:14px;
    }

    .wrapper, #headercontent, #pub {
        width: 100%;
    }

    /* Tableaux responsive */
    @import '../../design/helper/responsive/mobile/mobile';

    /* Fiche produit */
    .template_fiche_produit_1 #fiche-produit {
        .sans-declinaison {
            .pro-pannier, .description, .sociaux {
                float: none;
                width: 100%;
            }
        }
    }

    /* Suppression de la bare de réseaux sociaux */
    #minisite-sidebar {
        display: none;
    }

    /* Template diaporama accueil */
    .wrapper.accueil_diaporama_template.accueil_diaporama_template_5 .slide {
        .slide-text {
            height: 100%;
            &.nocomment {
                padding: 0 20px !important;
            }
            &.nocomment.nolink, &, &.nocomment {
                .content-slide-text > a.addcommentaires {
                    top: 10px !important;
                    bottom: auto !important;
                    right: 10px !important;
                    transform: translateX(0);
                }
            }
            .content-slide-text {
                height: 100%;
                > a.slider-see-more {
                    top: auto !important;
                    bottom: 10px !important;
                }
                > p {
                    font-size: 20px !important;
                    bottom: 50% !important;
                    transform: translateY(50%);
                }
            }
        }
    }


    /* Slider */
    .wrapper.accueil_diaporama_template,
    .accueil_diaporama_template {
        .slide .slide-text, .slide .slide-text.nocomment {
            height: 100% !important;
            width: 100% !important;
            top: 0 !important;
            left: 0 !important;
            transform: translateY(0%);
            padding: 0 !important;
            a.slider-see-more {
                bottom: auto !important;
                transform: translateY(0) translateX(0);
                top: 5px !important;
                left: 5px !important;
                right: auto !important;
                margin: 0 !important;
            }
            .content-slide-text > a.addcommentaires {
                transform: translateX(0);
                width: 75px !important;
                height: 55px !important;
                box-sizing: border-box;
                top: 5px !important;
                right: 5px !important;
                margin: 0 !important;
                padding: 0 !important;
                line-height: 55px !important;
                text-align: center;
            }
            .content-slide-text > p {
                position: absolute !important;
                bottom: 5px !important;
                top: auto !important;
                left: 5px !important;
                font-size: 12px !important;
                line-height: normal !important;
                text-align: left !important;
            }
        }
    }
    .wrapper.accueil_diaporama_template .slide .slide-text,
    .accueil_diaporama_template .slide .slide-text {
        padding: 50px 20px !important;
        width: 100% !important;
        left: 0 !important;
        .content-slide-text > a.addcommentaires {
            top: 5px !important;
        }
        .content-slide-text > p {
            margin: 0 !important;
            font-size: 25px;
        }
        .content-slide-text > a.slider-see-more {
            top: 5px !important;
            bottom: auto !important;
            max-width: calc(100% - 75px);
        }
    }
}

/************************** MODIFICATIONS TEMPLATES **************************************/

/* Slider */
.accueil_diaporama_template_2 {
    margin-bottom: 0;
}

.accueil_diaporama_template_4,
.accueil_diaporama_template_6 {
    margin-top: 0;
    ~ #wrapper {
        padding-top: 0;
    }
}

/* Produits */
.template_boutique{
    &.template_boutique_1 .produits{
        .block-btn-addbasket{
            align-items: flex-end;
            justify-content: flex-start;
            margin: 0 0 15px;
            .addbasket{
                margin-top: 5px;
                padding: 10px;
                &:not(.next-select){
                    width: auto;
                    max-width: 80%;
                    margin: 0 auto;
                    min-width: 45%;
                }
            }
        }
    }
    &.template_boutique_2 .produits{
        .attr {
            text-align: center;
            select {
                position: static;
                display: inline;
            }
        }
        .addbasket {
            padding: 5px;
            float: left;
            width:50%;
        }
        .prix .oldprix, .prix .prixprod{
            float:none;
        }
        .hors-stock {
            width: 100%;
            text-align: center;
        }
    }
    &.template_boutique_3 .produits{
        .prix {
            margin: 0 5px 10px 0;
        }
    }
    &.template_boutique_4 .produits{
        .nomprod {
            margin-top: 55px;
        }
        &.is_etiquette {
            .remise {
                top: 0;
            }
        }
        .container-etiquette{
            position: absolute;
        }
    }
    &.template_boutique_5 .produits{
        .container-etiquette .produit_etiquette h3,
        .container-etiquette .produit_etiquette .h3{
            padding: 3px 0;
        }
        .remise{
            right: auto;
            left: 10px;
            top:45px;
        }
    }
}

@media #{$small-and-down} {

    .template_boutique_5 .productImageWrap {
        height: auto;
    }
    .template_boutique_5 .desc {
        font-size: 13px;
        text-align: center;
    }
    .template_boutique_5 .prix {
        text-align: center;
    }
    .template_boutique_5 .prix__block {
        justify-content: center;
    }
    .template_boutique_5 .attr {
        display: block;
        margin: 5px 0;
    }
}

/* Produits accueil */

.produits-accueil.accueil_boutique_template_2 .produits {
    p.attr {
        text-align: center;
    }
}

.accueil_diaporama_template.accueil_diaporama_template_1 {
    .bx-wrapper .bx-pager {
        display: none !important;
    }
}

.wrapper.accueil_diaporama_template_2 .slide .center-img-slider {
    margin-bottom: 0;
}

.accueil_diaporama_template.accueil_diaporama_template_1 .slide-text .content-slide-text > a.addcommentaires, .accueil_diaporama_template.accueil_diaporama_template_3 .slide-text .content-slide-text > a.addcommentaires {
    top: 50px !important;
}

.accueil_diaporama_template.accueil_diaporama_template_1 .slide-text.nocomment .content-slide-text > a.addcommentaires, .accueil_diaporama_template.accueil_diaporama_template_3 .slide-text.nocomment .content-slide-text > a.addcommentaires {
    top: 20px !important;
}

#fiche-produit .wrap-description h3,
#fiche-produit .wrap-description .h3 {
    border: none !important;
}

.cmonsite-ficheproduit.cmonsite-template #bloc-fil-title {
    h2, .h2, h1, .h1 {
        display: none;
    }
}

.bxslider-diaporama {
    height: 100%;
    li {
        text-align: center;
        img {
            display: inline-block;
        }

    }
}