.contenu-editable, footer, .article-description {
  table:not(.fc-table){
    border-spacing: 0;
    padding: 0;
    tr {
      height: auto !important;
      display: block;
      width: 100%;
    }
    tr td, tbody, thead, tfoot {
      display: block;
      width: 100% !important;
      height: auto !important;
      border-spacing: 0;
      padding: 0;
    }
  }
  table {
    position: relative;
    width: 100% !important;
    max-width: 100% !important;
    table-layout: fixed;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}